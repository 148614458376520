import _ from "lodash";

class Basket {
    constructor(props) {
        /**
         * @property requestsProcessing
         * @type {Object}
         */
        this.requestsProcessing = {
            basket: new props.dependencies.RequestQueue()
        };

        /**
         * @property _events
         * @type {Object}
         * @private
         */
        this._events = {
            update: "update",
            addedItem: "addedItem",
            open: "open",
            closed: "closed"
        };

        /**
         * @property basket
         * @type {Object}
         */
        this.basket = null;

        /**
         * @property recommendations
         * @type {Product[]}
         */
        this.recommendations = [];

        this.observer = new props.dependencies.Observer().installTo(this);

        this.Env = props.dependencies.Env;
        this.Repository = props.dependencies.Repository;
        this.BasketEntity = props.dependencies.BasketEntity;
        this.ProductEntity = props.dependencies.ProductEntity;
        this.LocalStorage = props.dependencies.LocalStorage;
        this.LocalStorageEnum = props.dependencies.LocalStorageEnum;

        this._setBasket(
            this._buildBasket({}).setId(this._getBasketId()).getEntity()
        );
    }

    /**
     * @private
     * @method _hasItemByCurrentBasket
     * @param itemId {string|number}
     * @returns {boolean}
     */
    _hasItemByCurrentBasket(itemId) {
        return Boolean(this.basket.getItems().find((item) => item.getPosition().getId() === itemId));
    }

    /**
     * @private
     * @method _buildBasket
     * @param basket {Object}
     * @returns {Basket}
     */
    _buildBasket(basket) {
        return new this.BasketEntity(basket);
    }

    /**
     * @private
     * @method _buildRecommendations
     * @param recommendationItems {Array}
     * @returns {Product[]}
     */
    _buildRecommendations(recommendationItems) {
        // eslint-disable-next-line max-len
        return (Array.isArray(recommendationItems || []) && recommendationItems).map((item) => new this.ProductEntity(item));
    }

    /**
     * @private
     * @method _getBasketId
     * @returns {string}
     */
    _getBasketId() {
        return this.LocalStorage.getItem(this.LocalStorageEnum.getBasketIdAsValue());
    }

    /**
     * @private
     * @method _setBasketId
     * @param basketId {string}
     * @returns {Basket}
     */
    _setBasketId(basketId) {
        this.LocalStorage.setItem(this.LocalStorageEnum.getBasketIdAsValue(), basketId);
        this.Repository.setBasketId(basketId);

        return this;
    }

    /**
     * @private
     * @method _setBasket
     * @param basket {Object}
     * @returns {Basket}
     */
    _setBasket(basket) {
        this.basket = this._buildBasket(basket);
        this._setBasketId(this.basket.getId());

        return this;
    }

    /**
     * @private
     * @method _setRecommendations
     * @param recommendations {Array}
     * @returns {Basket}
     */
    _setRecommendations(recommendations) {
        this.recommendations = this._buildRecommendations(recommendations);

        return this;
    }

    /**
     * @public
     * @method hasItem
     * @param id {string|number}
     * @param success {Function}
     * @returns {Basket}
     */
    hasItemById(id, success) {
        if (this.basket.getId()) {
            success(this._hasItemByCurrentBasket(id));
        } else {
            this.getBasket(
                () => {
                    success(this._hasItemByCurrentBasket(id));
                },
                () => success(false)
            );
        }

        return this;
    }

    /**
     * @public
     * @method getEmptyBasket
     * @returns {Basket}
     */
    getEmptyBasket() {
        return this._buildBasket({});
    }

    /**
     * @public
     * @method getBasket
     * @param [success] {Function}
     * @param [error] {Function}
     * @returns {Basket}
     */
    getBasket(success, error) {
        this.requestsProcessing.basket.addSuccess(success).addError(error);

        if (!this.requestsProcessing.basket.isPending()) {
            this.requestsProcessing.basket.toPending();

            this.Repository
                .getBasket(
                    (basket) => {
                        this
                            ._setBasket(basket)
                            .observer
                            .trigger(this._events.update, this.basket.copy());

                        this.requestsProcessing.basket.success(this.basket.copy());
                    },
                    (exception) => {
                        this.requestsProcessing.basket.error(exception);
                    }
                )
                .then(() => {
                    this.requestsProcessing.basket.clear();
                })
                .catch(() => {
                    this.requestsProcessing.basket.clear();
                });
        }

        return this;
    }

    /**
     * @method getRecommendations
     * @param success {Function}
     * @param error {Function}
     * @returns {Basket}
     */
    getRecommendations(success, error) {
        if (_.isFunction(success) && _.isFunction(error)) {
            this.Repository.getRecommendations((recommendations) => {
                this._setRecommendations(recommendations);

                success(this.recommendations);
            }, error);
        }

        return this;
    }

    /**
     * @public
     * @param itemId {string}
     * @param success {Function}
     * @param error {Function}
     * @returns {Basket}
     */
    addItem(itemId, success, error) {
        if (itemId && _.isFunction(success) && _.isFunction(error)) {
            this
                .Repository
                .addItem(itemId, success, error)
                .then((basket) => {
                    if (basket) {
                        this
                            ._setBasket(basket)
                            .observer
                            .trigger(this._events.addedItem, this.basket.copy())
                            .trigger(this._events.update, this.basket.copy());
                    }
                });
        }

        return this;
    }

    /**
     * @public
     * @method deleteItem
     * @param itemId {string|number}
     * @param success {Function}
     * @param error {Function}
     * @returns {Basket}
     */
    deleteItem(itemId, success, error) {
        if (itemId && _.isFunction(success) && _.isFunction(error)) {
            this
                .Repository
                .changeCount(itemId, 0, success, error)
                .then((basket) => {
                    if (basket) {
                        this
                            ._setBasket(basket)
                            .observer
                            .trigger(this._events.update, this.basket.copy());
                    }
                });
        }

        return this;
    }

    /**
     * @public
     * @method changeCount
     * @param itemId {string|number}
     * @param quantity {number}
     * @param success {Function}
     * @param error {Function}
     * @returns {Basket}
     */
    changeCount(itemId, quantity, success, error) {
        if (itemId && _.isFinite(quantity) && _.isFunction(success) && _.isFunction(error)) {
            this
                .Repository
                .changeCount(itemId, quantity, success, error)
                .then((basket) => {
                    if (basket) {
                        this
                            ._setBasket(basket)
                            .observer
                            .trigger(this._events.update, this.basket.copy());
                    }
                });
        }

        return this;
    }

    /**
     * @public
     * @method open
     * @param [basket] {Basket}
     * @returns {Basket}
     */
    open(basket) {
        let currentBasket = basket instanceof this.BasketEntity ? basket : this.basket;

        if (currentBasket.getId()) {
            this.observer.trigger(this._events.open, currentBasket.copy());
        }

        return this;
    }

    /**
     * @method closed
     * @return {Basket}
     */
    closed() {
        this.observer.trigger(this._events.closed);

        return this;
    }

    /**
     * @public
     * @method toCheckout
     * @returns {Basket}
     */
    toCheckout() {
        window.location.href = `/checkout/`;

        return this;
    }

    /**
     * @public
     * @method createOrder
     * @param order {{itemId: string|number, name: string, phone: string, email: string}}
     * @param success {Function}
     * @param error {Function}
     * @returns {Basket}
     */
    createOrder(order, success, error) {
        if (order && _.isFunction(success) && _.isFunction(error)) {
            this
                .Repository
                .createOrder(order, success, error);
        }

        return this;
    }
}

export default Basket;
