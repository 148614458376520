/* eslint-disable max-len */
import _ from "lodash";

class Repository {
    constructor(props) {
        /**
         * @example
         *
         * @property urls
         * @type {Object}
         */
        this.urls = props.urls;

        /**
         * @property HttpClient
         * @type {HttpClient}
         */
        this.HttpClient = props.dependencies.HttpClient;

        /**
         * @property httpClient
         * @type {HttpClient}
         */
        this.httpClient = new this.HttpClient();

        /**
         * @property basketId
         * @type {String}
         */
        this.basketId = "";
    }

    /**
     * @public
     * @method setBasketId
     * @param id {string}
     * @returns {Repository}
     */
    setBasketId(id) {
        this.basketId = id;

        return this;
    }

    /**
     * @method getBasket
     * @param [success] {Function}
     * @param [error] {Function}
     * @returns {Promise}
     */
    getBasket(success = () => {}, error = () => {}) {
        return this.httpClient
            .setBaseUrl(this.urls.getBasket.domain)
            .request({
                path: this.urls.getBasket.path,
                method: this.HttpClient.methods.GET,
                adapterOptions: {
                    credentials: "include"
                },
                query: _.merge({}, this.urls.getBasket.query, {
                    basketId: this.basketId
                })
            })
            .then((response) => {
                success(response.data);
            }, error);
    }

    /**
     * @method getRecommendations
     * @param [success] {Function}
     * @param [error] {Function}
     * @returns {Promise}
     */
    getRecommendations(success, error) {
        return this.httpClient
            .setBaseUrl(this.urls.getRecommendations.domain)
            .request({
                path: this.urls.getRecommendations.path,
                method: this.HttpClient.methods.GET,
                adapterOptions: {
                    credentials: "include"
                }
            })
            .then((response) => {
                success(response.data);

                return response.data;
            }, error);
    }

    /**
     * @public
     * @method addItem
     * @param id {string}
     * @param [success] {Function}
     * @param [error] {Function}
     * @returns {Promise}
     */
    addItem(id, success = () => {}, error = () => {}) {
        return this.httpClient
            .setBaseUrl(this.urls.addItem.domain)
            .request({
                path: this.urls.addItem.path,
                method: this.HttpClient.methods.POST,
                adapterOptions: {
                    credentials: "include"
                },
                query: _.merge({}, this.urls.addItem.query, {
                    basketId: this.basketId
                }),
                body: {
                    productId: id,
                    quantity: 1
                }
            })
            .then(function (response) {
                success(response.data);

                return response.data;
            }, error);
    }

    /**
     * @public
     * @method changeCount
     * @param itemId {string}
     * @param quantity {number}
     * @param success {Function}
     * @param error {Function}
     * @returns {Promise}
     */
    changeCount(itemId, quantity, success, error) {
        return this.httpClient
            .setBaseUrl(this.urls.changeCount.domain)
            .request({
                path: this.urls.changeCount.path,
                method: this.HttpClient.methods.PATCH,
                adapterOptions: {
                    credentials: "include"
                },
                body: {
                    quantity
                },
                query: _.merge({}, this.urls.addItem.query, {
                    basketId: this.basketId
                }),
                params: {
                    id: itemId
                }
            })
            .then(function (response) {
                success(response.data);

                return response.data;
            }, error);
    }

    /**
     * @public
     * @method createOrder
     * @param order {Object}
     * @param success {Function}
     * @param error {Function}
     * @returns {Promise}
     */
    createOrder(order, success, error) {
        return this.httpClient
            .setBaseUrl(this.urls.createOrder.domain)
            .request({
                path: this.urls.createOrder.path,
                method: this.HttpClient.methods.POST,
                dataType: this.HttpClient.dataTypes.URLENCODED,
                adapterOptions: {
                    credentials: "include"
                },
                body: {
                    buyMode: "ONE",
                    itemId: order.itemId,
                    dubLetter: "s",
                    paysystemId: 1,
                    deliveryId: 2,
                    priceId: 1,
                    currencyCode: "UAH",
                    "new_order[FIO]": order.name,
                    "new_order[PHONE]": order.phone,
                    "new_order[EMAIL]": order.email,
                    buttonsubmit: 1649
                }
            })
            .then(function (response) {
                success(response.data);

                return response.data;
            }, error);
    }
}

export default Repository;
